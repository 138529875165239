import './Support.css';
import { useRef, useState, useEffect } from 'react';

function Support() {


	const [showMoreInfo, setShowMoreInfo] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function scrollToDocItem(element){
		element.scrollIntoView();
		if (window.scrollY){
			window.scrollTo(0, window.scrollY - 80);
		}
	}

	return (
		<div className="supportContainer">
			<div className='supportInnerContainer'>
				<p>
					<p style={{'font-size': '28px'}}>Please use the form below to add device-specific functionality to your synth.</p>
					<span>
						<p style={{'margin-top': '40px'}}>We need information from your synth in order to add device-specific support.</p>
						<p>The prompts on the form will describe what is needed in detail. Most fields are optional.</p>
						<p>The form data is used to reverse engineer the patch data to learn where the patch name is stored, if the bank & program numbers are stored as <a target="_blank" href="https://en.wikipedia.org/wiki/Zero-based_numbering">0-indexed</a> or 1-indexed, if a message contains a checsum (Roland👀), etc.</p>
					</span>
				</p>
				<br/>
				<br/>
				<a id='requestSupportButton' rel="noreferrer noopener" href="https://airtable.com/shrEImOdncmCBvDpz" target="_blank">Tell us about your synth</a>
				<br/>
				<br/>
			</div>
		</div>
	  );
}

export default Support;
