import './PageNotFound.css';
import emptyStand from '../assets/empty-stand.jpg';

function PageNotFound() {
  return (
    <div className="pageNotFound">
      <div className='innerContainer'>
        <h1>404</h1>
        <img className='emptyStand' src={emptyStand} alt='empty synth stand' />
      </div>
    </div>
  );
}

export default PageNotFound;
